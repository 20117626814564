import React from 'react';
import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Role } from 'src/common/types/user';
import { Country } from 'src/common/types/country';
import useAuth from '../../../hooks/useAuth';
interface ConditionalProps {
  children: ReactNode;
  roles?: Role[];
  countries?: Country[];
}
const ConditionalRender: FC<ConditionalProps> = ({
  children,
  roles,
  countries,
}) => {
  const { user } = useAuth();
  if (
    roles.filter((rol) => user.roles[0]?.name === rol)[0] &&
    countries?.filter((c) => c === process.env.REACT_APP_GM_INSTANCE_SLUG)[0]
  ) {
    return <>{children}</>;
  } else if (roles?.filter((rol) => user.roles[0]?.name === rol)[0]) {
    return <>{children}</>;
  } else if (
    countries?.filter((c) => c === process.env.REACT_APP_GM_INSTANCE_SLUG)[0]
  ) {
    return <>{children}</>;
  }
  return null;
};
ConditionalRender.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array,
  countries: PropTypes.array,
};

export default ConditionalRender;
