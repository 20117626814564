import App from './App';
import ReactDOM from 'react-dom';
import 'src/mocks';
import { HelmetProvider } from 'react-helmet-async';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import { TaskProvider } from './contexts/TasksContext';
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <TaskProvider>
        <SidebarProvider>
          <App />
        </SidebarProvider>
      </TaskProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);
