import React, { useState } from 'react';
import { Button, Paper, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';

import axios from 'src/utils/axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop:'-50px'
    },
    pageTitle: {
      ...theme.typography.h4,
      margin: 0
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(1)
      }
    },
    buttonContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center'
    },
    loading: {
      position: 'absolute'
    }
  })
);

function SiebelLeads() {
  const styles = useStyles();
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [notificationMessage, setMessage] = useState('');

  async function downloadFile() {
    try {
      setError(null);
      setLoading(true);
      const { data } = await axios.get<any>('/leads/download-leads-for-siebel');
      const { message, documentLink } = data;
      if (documentLink) {
        window.open(documentLink, '_blank');
        setMessage('Archivo descargado con éxito');
      } else {
        setError(message);
      }
    } catch (e) {
      setError(
        'Hubo un error descargando el archivo. Por favor intente más tarde'
      );
    } finally {
      setLoading(false);
    }
  }
  return (
    <section className={styles.top}>
      <h3 className={styles.pageTitle}>Descargar Leads para SIEBEL</h3>
      <Paper className={styles.paper}>
        <span>
          A continuación puede descargar el archivo de Leads para SIEBEL
        </span>
        {error && <Alert severity="error">{error}</Alert>}
        <div className={styles.buttonContainer}>
          <Button
            startIcon={<GetApp />}
            variant="contained"
            color="primary"
            size="large"
            disabled={loading}
            onClick={() => downloadFile()}
          >
            Descargar
          </Button>
          {loading && (
            <CircularProgress color="primary" className={styles.loading} />
          )}
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={notificationMessage !== ''}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
      >
        <Alert onClose={() => setMessage('')} severity="success">
          {notificationMessage}
        </Alert>
      </Snackbar>
    </section>
  );
}

export default SiebelLeads;
