import React from 'react';
import { Field, Form, Formik, FieldProps } from 'formik';
import { TextField, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { object, string, mixed } from 'yup';
import CampaignField from './CampaignField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(2)
    },
    fields: {
      display: 'flex',
      flexDirection: 'row'
    },
    formField: {
      minWidth: 150,
      marginTop: theme.spacing(2)
    },
    sourceField:{
      width:'100%'
    },
    buttons: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *:first-child': {
        marginBottom: theme.spacing(1)
      }
    }
  })
);

const sources = [
  { key: 'FACEBOOK', label: 'Facebook' },
  { key: 'DDP_FACEBOOK', label: 'DDP Facebook' },
  { key: 'CALL_CENTER', label: 'Call Center' },
  { key: 'RRSS', label: 'RRSS' },
  { key: 'GENERIC', label: 'Genérico' }
];

const validSources = sources.map((s) => s.key);

const validationSchema = object().shape({
  source: string()
    .required('Requerido')
    .oneOf(validSources, 'Selecciona una opción'),
  campaign: mixed().required('Requerido')
});

type YearMonthFormProps = {
  onSubmit: (values: any) => void;
  renderButtons: () => React.ReactNode;
};

function YearMonthForm({ onSubmit, renderButtons }: YearMonthFormProps) {
  const styles = useStyles();
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{ source: '', campaign: null }}
    >
      {({ errors }) => (
        <Form className={styles.form}>
          <Field name="source">
            {({ field }: FieldProps) => (
              <TextField
                select
                variant="outlined"
                label="Fuente"
                className={styles.sourceField}
                error={errors.source !== undefined}
                helperText={errors.source}
                {...field}
              >
                {sources.map((source) => (
                  <MenuItem key={source.key} value={source.key}>
                    {source.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
          <Field name="campaign">
            {({ field, form }: FieldProps) => (
              <div className={styles.formField}>
                <CampaignField
                  {...field}
                  onChange={(campaign) =>
                    form.setFieldValue(field.name, campaign)
                  }
                  error={errors.campaign !== undefined}
                  helperText={errors.campaign}
                />
              </div>
            )}
          </Field>
          <div className={styles.buttons}>{renderButtons()}</div>
        </Form>
      )}
    </Formik>
  );
}

export default YearMonthForm;
