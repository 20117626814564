import { FC, useState, createContext, Dispatch, SetStateAction } from 'react';
type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  customHeader: any;
  setCustomHeader: Dispatch<SetStateAction<string>>;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext,
);

export const SidebarProvider: FC = ({ children }) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [customHeader, setCustomHeader] = useState('Exacta');
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, customHeader, setCustomHeader }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
