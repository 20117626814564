import React, { useState } from 'react';
import { Button, Paper, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import InxaitUpload from './InxaitUpload';

import axios from '../../../utils/axios';
import { useTasks } from '../../../contexts/TasksContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 1,
      overflow: 'hidden'
    },
    pageTitle: {
      ...theme.typography.h4,
      margin: 0
    },
    main: {
      marginTop: theme.spacing(2),
      '& > *': {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        '&:last-child': {
          borderLeft: `1px solid ${theme.palette.divider}`
        }
      }
    },
    buttonContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1
    },
    loading: {
      position: 'absolute'
    },
    sectionTitle: {
      ...theme.typography.h5,
      margin: theme.spacing(0, 0, 2, 0)
    }
  })
);

function InxaitLeads() {
  const styles = useStyles();
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [notificationMessage, setMessage] = useState('');
  const { createPanel } = useTasks()
  createPanel('Principal')
  async function downloadFile() {
    try {
      setError(null);
      setLoading(true);
      const { data } = await axios.get<any>('/funnels/download');
      const { message, documentLink } = data;
      if (documentLink) {
        window.open(documentLink, '_blank');
        setMessage('Archivo descargado con éxito');
      } else {
        setError(message);
      }
    } catch (e) {
      setError(
        'Hubo un error descargando el archivo. Por favor intente más tarde'
      );
    } finally {
      setLoading(false);
    }
  }
  return (
    <section className={styles.top}>
      <h3 className={styles.pageTitle}>Inxait</h3>
      <Paper className={styles.main}>
        <div>
          <h4 className={styles.sectionTitle}>Carga</h4>
          <InxaitUpload />
        </div>
        <div>
          <h4 className={styles.sectionTitle}>Descarga</h4>
          {error && <Alert severity="error">{error}</Alert>}
          <div className={styles.buttonContainer}>
            <Button
              startIcon={<GetApp />}
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              onClick={() => downloadFile()}
            >
              Descargar Leads
            </Button>
            {loading && (
              <CircularProgress color="primary" className={styles.loading} />
            )}
          </div>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={notificationMessage !== ''}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
      >
        <Alert onClose={() => setMessage('')} severity="success">
          {notificationMessage}
        </Alert>
      </Snackbar>
    </section>
  );
}

export default InxaitLeads;
