import { FC, ReactNode } from 'react';

import { Instance } from '../../../common/types/user';
import { generalConfig } from '../../../config';

interface AuthenticatedProps {
  children?: ReactNode;
  instances?: Instance[] | null;
}

export const InstanceRender: FC<AuthenticatedProps> = ({
  children,
  instances,
}) => {
  const { instanceSlug } = generalConfig;

  if (instances) {
    const isInAtLeastOneInstance =
      instances.filter((instance) => instance === instanceSlug).length > 0;

    if (!isInAtLeastOneInstance) {
      return <></>;
    }
  }
  return <>{children}</>;
};
