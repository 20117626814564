import React from 'react';
import { Field, Form, Formik, FieldProps } from 'formik';
import { TextField, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { object, string } from 'yup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(0),
      marginTop: '10px'
    },
    formField: {
      minWidth: 240
    },
    buttons: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left'
    }
  })
);

const types = [
  { name: 'Normal', value: 'normal' },
  { name: 'Cierre Oficial', value: 'oficial' }
];

const validationSchema = object().shape({
  type: string()
    .required('Requerido')
    .oneOf(types.map((t) => t.value))
});

type ReportsFormProps = {
  onSubmit: (values: any) => void;
  renderButtons: () => React.ReactNode;
};

function ReportsForm({ onSubmit, renderButtons }: ReportsFormProps) {
  const styles = useStyles();
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={{ type: '' }}
    >
      {({ errors }) => (
        <Form className={styles.form}>
          <p>Tipo:</p>
          <Field name="type">
            {({ field }: FieldProps) => (
              <TextField
                select
                variant="outlined"
                className={styles.formField}
                error={errors.type !== undefined}
                helperText={errors.type}
                {...field}
              >
                {types.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
          <div className={styles.buttons}>{renderButtons()}</div>
        </Form>
      )}
    </Formik>
  );
}

export default ReportsForm;
