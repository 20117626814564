import { useEffect, useReducer } from "react"
import { Task } from "src/common/types/task"
import axios from '../../../../../../utils/axios'
type GetLiveStoreTasks = {
    type: 'get-live-store-tasks'
    payload: {
        tasks: Task[]
    }

}
type ActionTypes = GetLiveStoreTasks

type State = {
    tasks: Task[]
}
const initalState: State = {
    tasks: []
}
const Reducer = (state: State, action: ActionTypes) => {
    switch (action.type) {
        case 'get-live-store-tasks':
            const { tasks } = action.payload
            return {
                ...state,
                tasks
            }
        default:
            return state
    }

}
export interface LiveStoreTasksReducer extends State {
    getLiveStoreTasks: () => void
}
export const useLiveStoreTasksReducer = (): LiveStoreTasksReducer => {
    const [state, dispatch] = useReducer(Reducer, initalState)
    const getLiveStoreTasks = async () => {
        const { data: tasks } = await axios.get('/tasks/dealer-live-store')
        dispatch({
            type: 'get-live-store-tasks',
            payload: {
                tasks
            }
        })
    }
    useEffect(() => {
        getLiveStoreTasks()
    }, [])
    return {
        ...state,
        getLiveStoreTasks
    }
}
