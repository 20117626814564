import React, { useState } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Autocomplete,
  TextField,
  Box,
  Typography,
} from '@material-ui/core';
/* import SalesUpload from '../FileLoader/SalesLoads'; */
import { experimentalStyled } from '@material-ui/core/styles';
/* import DirectUpload from '../FileLoader/DirectLoads'; */

import SalesUpload from '../FileLoader/SalesLoads';
import LeadsOverAllUpload from '../FileLoader/LeadsOverAll';
import FranchiseLeadsUpload from '../FileLoader/LeadsFranchiseLoads';
import SourceUpload from '../FileLoader/SourceLoads';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      color: 'white !important',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.dark} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.main} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.light} !important`,
      },
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: 'red !important',
      },
    },
  }),
);

const UploadBox = experimentalStyled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
`,
);

const TypographyPrimary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
  `,
);

const TypographySecondary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[70]};
  `,
);

type UploadTypes =
  | 'leads_overAll'
  | 'sales_upload'
  | 'franchise_upload'
  | 'sources_upload';

const AvailableUploadTypes: { label: string; value: UploadTypes }[] = [
  /*  {
    label: 'Carga Leads Overall',
    value: 'leads_overAll',
  },
  {
    label: 'Carga Leads Franchise',
    value: 'franchise_upload',
  },
  {
    label: 'Carga Ventas',
    value: 'sales_upload',
  }, */
  {
    label: 'Carga Fuentes',
    value: 'sources_upload',
  },
];

const SelectExataUpload = () => {
  const styles = useStyles();
  const [upload, setUpload] = useState<UploadTypes | null>('sources_upload');

  return (
    <UploadBox>
      <TypographyPrimary variant="h4" gutterBottom>
        Carga Rápida
      </TypographyPrimary>
      <TypographySecondary variant="body1">
        Seleccione el tipo de carga
      </TypographySecondary>

      <Box sx={{ my: 2 }}>
        <Autocomplete
          classes={styles}
          disablePortal
          options={AvailableUploadTypes}
          onChange={(_, value: { label: string; value: UploadTypes } | null) =>
            setUpload(value ? value.value : null)
          }
          defaultValue={{
            label: 'Carga Fuentes',
            value: 'sources_upload',
          }}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                style: { color: 'white' },
              }}
              fullWidth
              label="Tipo de carga"
            />
          )}
        />

        {upload === 'leads_overAll' && <LeadsOverAllUpload />}
      </Box>

      {upload === 'sales_upload' && <SalesUpload />}

      {upload === 'franchise_upload' && <FranchiseLeadsUpload />}
      {upload === 'sources_upload' && <SourceUpload />}
    </UploadBox>
  );
};

export default SelectExataUpload;
