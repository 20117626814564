import {
  Button,
  Drawer,
  experimentalStyled,
  useTheme,
} from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import React, { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { ExactaSidebarPanel } from 'src/content/dashboards/InicioExacta/Sidebar';
import { useTasks } from 'src/contexts/TasksContext';
import { Sidebar } from '../../../../../content/dashboards/Inicio/Sidebar/index';
import { colors } from '../../../../../theme/schemes/GmTheme';

const DrawerWrapperMobile = experimentalStyled(Drawer)(
  ({ theme }) => `
      width: 350px;
      flex-shrink: 0;
  
    & > .MuiPaper-root {
          width: 370px;
          z-index: 3;
          background: ${theme.colors.gradients.blue3};
          color: ${theme.colors.alpha.white[100]};
    }
  `,
);

const HeaderTasks = () => {
  const [rightBar, setRigthBar] = useState(false);
  const handleRigthBar = () => {
    setRigthBar(!rightBar);
  };
  const { typePanel } = useTasks();
  const theme = useTheme();
  const sidebarContent =
    typePanel === 'Principal' ? (
      <Scrollbars autoHide>
        <Sidebar />
      </Scrollbars>
    ) : (
      <Scrollbars autoHide>
        <ExactaSidebarPanel />
      </Scrollbars>
    );
  return (
    <>
      <DrawerWrapperMobile
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        open={rightBar}
        onClose={handleRigthBar}
      >
        {sidebarContent}
      </DrawerWrapperMobile>
      <Button sx={{ color: colors.gm.white }} onClick={handleRigthBar}>
        <Assignment sx={{ mr: 1 }} />
        Tareas
      </Button>
    </>
  );
};

export default HeaderTasks;
