import React, { useRef } from 'react';
import { Button, CircularProgress, Link } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useInxaitUpload } from './reducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperTitle: {
      ...theme.typography.h5,
      margin: 0,
      marginBottom: theme.spacing(2),
      '&.warning': {
        color: theme.palette.error.main,
      },
    },
    container: {
      display: 'grid',
      gridTemplateRows: '120 auto',
      gridGap: theme.spacing(2),
      alignItems: 'start',
      '& input': {
        display: 'none',
      },
    },
    paper: {
      padding: theme.spacing(2),
    },
    uploadArea: {
      marginTop: theme.spacing(2),
      position: 'relative',
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px dashed ${theme.palette.divider}`,
      '& > button': {
        marginTop: theme.spacing(1),
      },
    },
    loadingIndicator: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    },
    fileName: {
      ...theme.typography.h6,
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  })
);

const liveStoreFormatUrl = `${process.env.PUBLIC_URL}/live-store-format.xlsx`;

function InxaitUpload(props: any) {
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    loading,
    status,
    fileName,
    error,
    validationErrors,
    selectFile,
    uploadFile,
    reset,
  } = useInxaitUpload(inputRef);
  return (
    <div className={styles.container}>
      <div className={styles.uploadArea}>
        <input
          ref={inputRef}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id="contained-button-file"
          type="file"
          onChange={selectFile}
        />
        {status === 'idle' && (
          <label htmlFor="contained-button-file">
            <Button variant="outlined" color="primary" component="span">
              Seleccionar Archivo
            </Button>
          </label>
        )}
        {(status === 'file-selected' || loading) && (
          <>
            {loading && <CircularProgress color="primary" />}
            <span className={styles.fileName}>{fileName}</span>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={uploadFile}
            >
              Subir
            </Button>
            <Button onClick={reset}>Cancelar</Button>
          </>
        )}
        {status === 'success' && (
          <>
            <Alert severity="success">Se ha subido tu archivo con éxito</Alert>
            <Button onClick={reset}>Continuar</Button>
          </>
        )}
        {status === 'error' && (
          <>
            <Alert severity="error">{error}</Alert>
            <Button onClick={reset}>Continuar</Button>
          </>
        )}
      </div>
      {validationErrors.length > 0 ? (
        <>
          <span>
            Estos fueron los errores que encontramos en el último archivo que
            cargaste:
          </span>
          <ul>
            {validationErrors.map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <span>
            Recuerde que para marcar SMS se utiliza 0 y para Email 1. Las fechas
            deben estar en el formato{' '}
            <strong>día/mes/año horas:minutos. </strong> Si necesita un ejemplo
            del archivo{' '}
            <Link href={liveStoreFormatUrl}>puede encontrarlo aquí.</Link>
          </span>
        </>
      )}
    </div>
  );
}

export default InxaitUpload;
