import React, { useState } from 'react';
import { Button, Paper, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Input } from '@material-ui/icons';
import axios from 'src/utils/axios';
import ReportsForm from './ReportsForm';
/* import NewReportsForm from './NewReportsForm'; */
/* import { useReportReducer } from './reducer';
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    pageTitle: {
      ...theme.typography.h4,
      margin: 0,
    },
    main: {
      marginTop: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: '1fr',
      '& > *': {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        '&:last-child': {
          borderLeft: `1px solid ${theme.palette.divider}`,
        },
      },
    },
    buttonContainer: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'left',
    },
    loading: {
      position: 'absolute',
    },
    sectionTitle: {
      ...theme.typography.h5,
      margin: theme.spacing(0, 0, 2, 0),
    },
  }),
);

function SiebelLeads() {
  const styles = useStyles();
  const [error, setError] = useState<null | string>(null);
  /*   const [errorLastYear, setErrorLastYear] = useState<null | string>(null); */
  const [loading, setLoading] = useState(false);
  const [notificationMessage, setMessage] = useState('');
  /* const reducerValue = useReportReducer(); */
  async function generateReports(type: string) {
    try {
      setError(null);
      setLoading(true);
      await axios.get(`/reports/generate-all-colors-reports/${type}`);
      setMessage('Reportes generados con éxito');
    } catch (e) {
      setError(
        'Hubo un error generando los reportes. Por favor intente más tarde.',
      );
    } finally {
      setLoading(false);
    }
  }
  /*  async function generateLastYearReport() {
     try {
       setErrorLastYear(null);
       setLoading(true);
       await axios.get(`/reports/generate-last-year-colors-reports`);
       setMessage('Reportes generados con éxito');
     } catch (e) {
       setErrorLastYear(
         'Hubo un error generando los reportes. Por favor intente más tarde.',
       );
     } finally {
       setLoading(false);
     }
   } */
  return (
    <section className={styles.top}>
      <Paper className={styles.main}>
        <div>
          <span>A continuación puede generar los reportes de ventas</span>
          {error && <Alert severity="error">{error}</Alert>}
          <ReportsForm
            onSubmit={(values) => generateReports(values.type)}
            renderButtons={() => (
              <div className={styles.buttonContainer}>
                <Button
                  startIcon={<Input />}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  disabled={loading}
                >
                  Generar
                </Button>
                {loading && (
                  <CircularProgress
                    color="primary"
                    className={styles.loading}
                  />
                )}
              </div>
            )}
          />
        </div>

        {/* <div>
          <h5 className={styles.pageTitle}>Generar reportes de ventas</h5>
          <NewReportsForm {...reducerValue} />
        </div> */}
        {/* <div>
          <h4 className={styles.sectionTitle}>Generar Reporte Año Anterior</h4>
          {errorLastYear && <Alert severity="error">{errorLastYear}</Alert>}
          <div className={styles.buttonContainer}>
            <Button
              startIcon={<Input />}
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              onClick={() => generateLastYearReport()}
            >
              Generar
            </Button>
            {loading && (
              <CircularProgress color="primary" className={styles.loading} />
            )}
          </div>
        </div> */}
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={notificationMessage !== ''}
        onClose={() => setMessage('')}
      >
        <Alert onClose={() => setMessage('')} severity="success">
          {notificationMessage}
        </Alert>
      </Snackbar>
    </section>
  );
}

export default SiebelLeads;
