import React from 'react';
import { Field, Form, Formik, FieldProps } from 'formik';
import { TextField, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { object, string } from 'yup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(2),
    },
    formField: {
      width: '100%',
      '&.margin': {
        marginTop: theme.spacing(1),
      },
    },
    buttons: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);

const validationSchemaGlobal = object().shape({
  year: string().required('Requerido'),
});

const currentYear = new Date().getFullYear();

const years = (() => {
  let year = 2019;
  const res = [];
  while (year <= currentYear) {
    res.push(`${year}`);
    year += 1;
  }
  return res;
})();

type YearMonthFormProps = {
  onSubmit: (values: any) => void;
  renderButtons: () => React.ReactNode;
};

function YearMonthForm({ onSubmit, renderButtons }: YearMonthFormProps) {
  const styles = useStyles();
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchemaGlobal}
      onSubmit={onSubmit}
      initialValues={{ year: '' }}
    >
      {({ errors }) => (
        <Form className={styles.form}>
          <Field name="year">
            {({ field }: FieldProps) => (
              <TextField
                select
                variant="outlined"
                label="Año"
                className={`${styles.formField} margin`}
                error={errors.year !== undefined}
                helperText={errors.year}
                {...field}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
          <div className={styles.buttons}>{renderButtons()}</div>
        </Form>
      )}
    </Formik>
  );
}

export default YearMonthForm;
