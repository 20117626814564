import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Instance } from '../../../common/types/user';
import { generalConfig } from '../../../config';

interface AuthenticatedProps {
  children?: ReactNode;
  instances?: Instance[] | null;
}

const InstanceGuard: FC<AuthenticatedProps> = ({ children, instances }) => {
  const { instanceSlug } = generalConfig;

  if (instances) {
    const isInAtLeastOneInstance =
      instances.filter((instance) => instance === instanceSlug).length > 0;

    if (!isInAtLeastOneInstance) {
      return <Redirect to="/not-found" />;
    }
  }

  return <>{children}</>;
};

InstanceGuard.propTypes = {
  children: PropTypes.node,
  instances: PropTypes.array,
};

export default InstanceGuard;
