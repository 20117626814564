import React from 'react';
import { EC, CL, CO, PE, BR, AR } from 'country-flag-icons/react/3x2';
const instance = process.env.REACT_APP_GM_INSTANCE_SLUG;
const FlagIcon = () => {
  switch (instance) {
    case 'ec':
      return (
        <div style={{ display: 'flex', marginLeft: 15 }}>
          <EC title="Ecuador" style={{ width: 50, height: 50 }} />
        </div>
      );
    case 'cl':
      return (
        <div style={{ display: 'flex', marginLeft: 15 }}>
          <CL title="Chile" style={{ width: 50, height: 50 }} />
        </div>
      );
    case 'co':
      return (
        <div style={{ display: 'flex', marginLeft: 15 }}>
          <CO title="Colombia" style={{ width: 50, height: 50 }} />
        </div>
      );
    case 'pe':
      return (
        <div style={{ display: 'flex', marginLeft: 15 }}>
          <PE title="Peru" style={{ width: 50, height: 50 }} />
        </div>
      );
    case 'br':
      return (
        <div style={{ display: 'flex', marginLeft: 15 }}>
          <BR title="Brasil" style={{ width: 50, height: 50 }} />
        </div>
      );
    case 'ar':
      return (
        <div style={{ display: 'flex', marginLeft: 15 }}>
          <AR title="Argentina" style={{ width: 50, height: 50 }} />
        </div>
      );
    default:
      break;
  }
};

export default FlagIcon;
