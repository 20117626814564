import { useRef, useState } from 'react';
import {
  Box,
  Button,

  Hidden,
  lighten,

  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';

import { colors } from '../../../../theme/schemes/GmTheme';


import { Download } from '@material-ui/icons';
import { DowloandReportStyles } from './styles/styles';
import SiebelLeads from './SiebelReports';

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);



const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
);

function HeaderDownloadReportBox() {
  const styles = DowloandReportStyles

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };


  return (
    <>
      <Tooltip title="Generar reportes de ventas">
        <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
          <Download style={styles.downloadIcon} />
          <Hidden mdDown>
            <UserBoxText>
              <UserBoxLabel
                variant="body1"
                sx={{
                  color: colors.gm.white,
                }}
              >
              </UserBoxLabel>
              <UserBoxDescription
                variant="body2"
                sx={{
                  // color: colors.gm.white,
                  color: '#F00',
                }}
              >
              </UserBoxDescription>
            </UserBoxText>
          </Hidden >
        </UserBoxButton >
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box>
          <SiebelLeads />
        </Box>

      </Popover>

    </>
  );
}


export default HeaderDownloadReportBox;
