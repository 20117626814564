import { Card, CardActions, CardHeader, Container, Divider, Grid, IconButton, InputAdornment, Link, List, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'

import React, { ChangeEvent, useState } from 'react'
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import ContentWrapper from 'src/components/ContentWrapper'

import { useLiveStoreTasksReducer } from './reducer'
import LastLiveStoreTasks from './Results';


const LiveStoreTasks = () => {

  const { tasks, getLiveStoreTasks } = useLiveStoreTasksReducer()
  const [search, setSearch] = useState<string>('');
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setSearch(event.target.value);
  };
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const lastFiveLiveStoreTasks = [
    tasks[tasks.length - 1],
    tasks[tasks.length - 2],
    tasks[tasks.length - 3],
    tasks[tasks.length - 4],
    tasks[tasks.length - 5],
  ];
  const filteredTasks =
    lastFiveLiveStoreTasks.filter((task) =>
      task?.filePath.toLowerCase().includes(search.toLowerCase()),
    );
  return (
    <>
      {tasks.length > 0 ? (
        <ContentWrapper title="Tasks - Management">
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    action={
                      <>
                        <TextField
                          size="small"
                          fullWidth={mobile}
                          onChange={handleQueryChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchTwoToneIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder={'Busque una Tarea...'}
                        />
                      </>
                    }
                    title={'Tareas'}
                  />

                  <Link
                    href="/dashboards/livestore/pending-tasks"
                    underline="none"
                    style={{ margin: 10 }}
                  >
                    {'Ver mas...'}
                  </Link>
                  <IconButton
                    aria-label="refresh"
                    color="primary"
                    onClick={() => getLiveStoreTasks()}
                    title="Refrescar Tareas"
                  >
                    <Refresh />
                  </IconButton>
                  <Divider />
                  <List disablePadding>
                    <Divider component="li" />
                    <LastLiveStoreTasks tasks={filteredTasks} />
                  </List>
                  <CardActions
                    disableSpacing
                    sx={{ p: 2, display: 'flex', justifyContent: 'center' }}
                  ></CardActions>

                </Card>
              </Grid>
            </Grid>
          </Container>
        </ContentWrapper>
      ) : (<Container>
        <Typography variant='h4' marginBottom={2}>
          No tienes tareas pendientes
        </Typography>
      </Container>)}

    </>
  )
}

export default LiveStoreTasks