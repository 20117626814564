import { useReducer } from 'react';

import axios from '../../../../../utils/axios';

type State = {
  status: 'idle' | 'file-selected' | 'loading' | 'error' | 'success';
  fileName: string | null;
  error: string | null;
  validationErrors: string[];
};

type Actions =
  | { type: 'select-file'; fileName: string }
  | { type: 'upload-file' }
  | { type: 'reset' }
  | { type: 'success' }
  | { type: 'error'; error: string; validationErrors?: string[] };

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'select-file':
      return {
        ...state,
        fileName: action.fileName,
        status: 'file-selected',
      };
    case 'upload-file':
      return { ...state, status: 'loading' };
    case 'reset':
      return { ...state, status: 'idle', fileName: null };
    case 'success':
      return { ...state, status: 'success', validationErrors: [] };
    case 'error':
      return {
        ...state,
        status: 'error',
        error: action.error,
        fileName: null,
        validationErrors: action.validationErrors || [],
      };
    default:
      return state;
  }
};

const initialState: State = {
  status: 'idle',
  fileName: null,
  error: null,
  validationErrors: [],
};

export function useSalesUpload(inputField: React.RefObject<HTMLInputElement>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function selectFile() {
    if (inputField.current) {
      if (inputField.current.files) {
        dispatch({
          type: 'select-file',
          fileName: inputField.current.files[0].name,
        });
      }
    }
  }
  async function uploadFile(month: string, year: string) {
    dispatch({ type: 'upload-file' });
    try {
      if (inputField.current) {
        if (inputField.current.files) {
          const formData = new FormData();
          formData.append('file', inputField.current.files[0]);
          formData.append('month', month);
          formData.append('year', year);
          await axios.post('/uploads/raw-not-contacted-leads', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
      }
      dispatch({ type: 'success' });
    } catch (e) {
      if (e) {
        if (e.statusCode === 400) {
          // There was a validation error
          // Get the errors separated by "."
          const validationErrors = e.message.split('.');
          dispatch({
            type: 'error',
            error: `Hubo un error de validación. Por favor revise el archivo ${validationErrors}`,
            validationErrors,
          });
        }
      } else {
        dispatch({
          type: 'error',
          error:
            'Hubo un error cargando su archivo. Por favor intente más tarde',
        });
      }
    }
  }
  function reset() {
    if (inputField.current) {
      inputField.current.value = '';
    }
    dispatch({ type: 'reset' });
  }
  return {
    ...state,
    loading: state.status === 'loading',
    selectFile,
    uploadFile,
    reset,
  };
}
