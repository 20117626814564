import React, { useRef } from 'react';
import { Paper, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFranchiseLeadsUpload } from './reducer';
import YearMonthForm from './YearMonthForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    top: {
      padding: theme.spacing(1),
    },
    pageTitle: {
      ...theme.typography.h4,
      margin: 0,
      marginBottom: theme.spacing(2),
    },
    paperTitle: {
      ...theme.typography.h5,
      margin: 0,
      marginBottom: theme.spacing(2),
      '&.warning': {
        color: theme.palette.error.main,
      },
    },
    papers: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
      alignItems: 'start',
      '& input': {
        display: 'none',
      },
    },
    paper: {
      padding: theme.spacing(1),
    },
    uploadArea: {
      marginTop: theme.spacing(0),
      position: 'relative',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px dashed ${theme.palette.divider}`,
      '& > button': {
        marginTop: theme.spacing(1),
      },
    },
    loadingIndicator: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    },
    fileName: {
      ...theme.typography.h6,
      margin: 0,
      marginBottom: theme.spacing(1),
    },
    validationErrors: {
      maxHeight: '60vh',
      overflow: 'auto',
    },
  }),
);

function FranchiseLeadsUpload(props: any) {
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    loading,
    status,
    fileName,
    error,
    selectFile,
    uploadFile,
    reset,
  } = useFranchiseLeadsUpload(inputRef);
  return (
    <section className={styles.top}>
      <div className={styles.papers}>
        <Paper className={styles.paper}>
          <div className={styles.uploadArea}>
            <input
              ref={inputRef}
              accept=".xlsx"
              id="contained-button-file-sales"
              type="file"
              onChange={selectFile}
            />
            {status === 'idle' && (
              <label htmlFor="contained-button-file-sales">
                <Button variant="outlined" color="primary" component="span">
                  Seleccionar Archivo
                </Button>
              </label>
            )}
            {(status === 'file-selected' || loading) && (
              <>
                {loading && <CircularProgress color="primary" />}
                <span className={styles.fileName}>{fileName}</span>
                <YearMonthForm
                  onSubmit={(values) => uploadFile(values.year)}
                  renderButtons={() => (
                    <>
                      <Button
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Subir
                      </Button>
                      <Button onClick={reset}>Cancelar</Button>
                    </>
                  )}
                />
              </>
            )}
            {status === 'success' && (
              <>
                <Alert severity="success">
                  Se ha subido tu archivo con éxito
                </Alert>
                <Button onClick={reset}>Continuar</Button>
              </>
            )}
            {status === 'error' && (
              <>
                <Alert severity="error">{error}</Alert>
                <Button onClick={reset}>Continuar</Button>
              </>
            )}
          </div>
        </Paper>
      </div>
    </section>
  );
}

export default FranchiseLeadsUpload;
