export const instances = [
  {
    name: 'chile',
    // url: 'http://localhost:3000'
    url: 'https://chile.gminfoleads.com',
  },
  {
    name: 'ecuador',
    url: 'https://ecuador.gminfoleads.com',
  },
  {
    name: 'colombia',
    // url: 'http://localhost:3001'
    url: 'https://colombia.gminfoleads.com',
  },
  {
    name: 'peru',
    url: 'https://peru.gminfoleads.com',
  },
];
