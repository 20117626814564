import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Authenticated from 'src/components/Guards/Authenticated';
import Guest from 'src/components/Guest';

import { Role, Instance } from './common/types/user';
import RolesGuard from './components/Guards/Roles/routes';
import InstanceGuard from './components/Guards/Instances/routes';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  roles?: Role[];
  instances?: Instance[];
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <InstanceGuard instances={route.instances && route.instances}>
                  <RolesGuard roles={route.roles && route.roles}>
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  </RolesGuard>
                </InstanceGuard>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    guard: Authenticated,
    layout: SidebarLayout,
    path: '/not-found',
    component: lazy(() => import('./content/pages/Status/Status404/index')),
  },
  {
    path: '/dashboards',
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      /*   {
        exact: true,
        path: '/dashboards/inicio-new',
        component: lazy(() => import('src/content/dashboards/InicioExacta')),
        roles: ['admin', 'dealer', 'gm', 'inxait', 'callcenter'],
      }, */
      {
        exact: true,
        path: '/dashboards/reports-exata/summary',
        component: lazy(
          () =>
            import('src/content/dashboards/Reports/DealersFunnel/SummaryExata'),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/exata-sales-reports',
        component: lazy(
          () => import('src/content/dashboards/InicioExacta/ExataSalesReports'),
        ),
        roles: ['admin', 'dealer', 'gm', 'callcenter'],
      },
      {
        exact: true,
        path: '/dashboards/reports/exata-digital-funnel',
        component: lazy(
          () =>
            import(
              'src/content/dashboards/Reports/DealersFunnel/GraphsExata/T1Funnel'
            ),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/reports/exata-digital-funnel-ddp',
        component: lazy(
          () =>
            import(
              'src/content/dashboards/Reports/DealersFunnel/GraphsExata/DdpFunnel'
            ),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/exata/contactability',
        component: lazy(
          () => import('src/content/dashboards/InicioExacta/Contactability'),
        ),
        roles: ['admin', 'gm', 'dealer'],
      },
      {
        exact: true,
        path: '/dashboards/concierge-panel',
        component: lazy(() => import('src/content/dashboards/ConciergePanel')),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/dealer-callcenter',
        component: lazy(
          () => import('src/content/dashboards/DealerCallcenter'),
        ),
        roles: ['admin', 'dealer', 'callcenter_dealer', 'supervisor_dealer'],
      },
      {
        exact: true,
        path: '/dashboards/dealer-supervisor',
        component: lazy(
          () => import('src/content/dashboards/DealerSupervisor'),
        ),
        roles: ['admin', 'dealer', 'supervisor_dealer', 'callcenter_dealer'],
      },
      {
        exact: true,
        path: '/dashboards/reports/digital-funnel',
        component: lazy(
          () =>
            import(
              'src/content/dashboards/Reports/DealersFunnel/Graphs/T1Funnel'
            ),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/livestore/dealers',
        component: lazy(
          () => import('src/content/dashboards/LiveStore/Dealers'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/livestore/report',
        component: lazy(
          () => import('src/content/dashboards/LiveStore/Report/Index'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/livestore/management',
        component: lazy(
          () => import('src/content/dashboards/LiveStore/Management'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/ddp-report',
        component: lazy(
          () => import('src/content/dashboards/Reports/DdpReport/Index'),
        ),
        roles: ['admin', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/livestore/gmf',
        component: lazy(() => import('src/content/dashboards/LiveStore/GMF')),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/livestore/virtual-experience',
        component: lazy(
          () => import('src/content/dashboards/LiveStore/VirtualExperience'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/reports/digital-funnel-ddp',
        component: lazy(
          () =>
            import(
              'src/content/dashboards/Reports/DealersFunnel/Graphs/DdpFunnel'
            ),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/reports/summary',
        component: lazy(
          () => import('src/content/dashboards/Reports/DealersFunnel/Summary'),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/reports/overall',
        component: lazy(
          () => import('src/content/dashboards/Reports/OverallAnalysis'),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/reports/franchise',
        component: lazy(
          () => import('src/content/dashboards/Reports/FranchiseReport'),
        ),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/livestore/pending-tasks',
        component: lazy(
          () => import('src/content/dashboards/LiveStore/PendingTasks'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/expert-panel',
        component: lazy(() => import('src/content/dashboards/ExpertPanel')),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/files-loader/direct',
        component: lazy(
          () => import('src/content/dashboards/FileLoader/DirectLoads'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/files-loader/sales',
        component: lazy(
          () => import('src/content/dashboards/FileLoader/SalesLoads'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/files-loader/sources',
        component: lazy(
          () => import('src/content/dashboards/FileLoader/SourceLoads'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/pending-tasks',
        component: lazy(() => import('src/content/dashboards/PendingTasks')),
        roles: ['admin', 'inxait', 'callcenter'],
      },
      {
        exact: true,
        path: '/dashboards/contactability',
        component: lazy(() => import('src/content/dashboards/Contactability')),
        roles: ['admin', 'gm', 'dealer'],
      },
      {
        exact: true,
        path: '/dashboards/sales-reports',
        component: lazy(() => import('src/content/dashboards/SalesReports')),
        roles: ['admin', 'dealer', 'gm', 'callcenter'],
      },
      {
        exact: true,
        path: '/dashboards/siebel/leads',
        component: lazy(
          () => import('src/content/dashboards/Siebel/SiebelLeads'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/siebel/reports',
        component: lazy(
          () => import('src/content/dashboards/Siebel/SiebelReports'),
        ),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/search-leads',
        component: lazy(() => import('src/content/dashboards/SearchLeads')),
        roles: [
          'admin',
          'dealer',
          'supervisor_dealer',
          'callcenter_dealer',
          'gm',
        ],
      },
      {
        exact: true,
        path: '/dashboards/report-leads-by-form',
        component: lazy(() => import('src/content/dashboards/LeadsByForm')),
        roles: ['admin', 'dealer', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/inxait',
        component: lazy(() => import('src/content/dashboards/Inxait')),
        roles: ['admin', 'inxait'],
      },
      {
        exact: true,
        path: '/dashboards/inxait-events',
        component: lazy(() => import('src/content/dashboards/Confirmations')),
        roles: ['admin', 'inxait'],
      },
      {
        exact: true,
        path: '/dashboards/faq',
        component: lazy(() => import('src/content/dashboards/Faq')),
        roles: ['admin', 'dealer', 'gm', 'callcenter'],
      },
      {
        exact: true,
        path: '/dashboards/daily-access',
        component: lazy(() => import('src/content/dashboards/DailyAccess')),
        roles: ['admin', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/expert-calendars',
        component: lazy(() => import('src/content/dashboards/ExpertSchedules')),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/flags',
        component: lazy(() => import('src/content/dashboards/Flags')),
        roles: ['admin'],
      },
      {
        exact: true,
        path: '/dashboards/tickets',
        component: lazy(() => import('src/content/dashboards/TicketsPanel')),
        roles: ['admin', 'gm'],
      },
      {
        exact: true,
        path: '/dashboards/dealer-tickets',
        component: lazy(
          () => import('src/content/dashboards/TicketsPanel/DealerBox'),
        ),
        roles: ['admin', 'gm', 'dealer'],
      },
      {
        exact: true,
        path: '/dashboards/board',
        component: lazy(
          () => import('src/content/dashboards/TicketsPanel/Dashboard'),
        ),
        roles: [
          'admin' /* , 'autotrain', 'callcenter', 'callcenter_dealer', 'dealer', 'gm', 'inxait', 'nodo', 'supervisor_dealer' */,
        ],
      },
      {
        exact: true,
        path: '/dashboards/configurations',
        component: lazy(
          () => import('src/content/dashboards/Inicio/Configurations'),
        ),
        roles: ['admin'],
      },
    ],
  },
  {
    path: '/management',
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      {
        component: () => <Redirect to="/status/404" />,
      },
    ],
  },
  {
    exact: true,
    path: '/overview',
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    guard: Guest,
    path: '/login',
    component: lazy(() => import('src/content/pages/Auth/Login/Cover')),
  },
  {
    path: '*',
    layout: BaseLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />,
      },
      {
        component: () => <Redirect to="/login" />,
      },
    ],
  },
];

export default routes;
