import {
  Box,
  Card,
  Container,
  Divider,
  experimentalStyled,
  Link,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { ContactTask } from 'src/common/types/contactTask';
import Text from 'src/components/Text';
import { getMonthName } from 'src/utils/common-helpers';
import { useContactTasksReducer } from './reducer';

const ListItemWrapper = experimentalStyled(ListItem)(
  ({ theme }) => `
          border-radius: 0;
          padding: ${theme.spacing(3)};
  `,
);
type YearAndMonth = {
  year: number;
  month: number;
};
const ContactabilityTasks = () => {
  const { tasks } = useContactTasksReducer();

  return (
    <>
      <Container>
        <Typography variant="h4" marginBottom={2}>
          Últimos archivos cargados
        </Typography>
        <Card>
          {tasks.map((task: ContactTask) => {
            const yearAndMonth: YearAndMonth = JSON.parse(task.Task_data);
            const month = getMonthName(yearAndMonth.month.toString());
            return (
              <Box key={task.Task_id}>
                <ListItemWrapper button>
                  <ListItemText
                    primary={(task?.Task_filePath || '').split('/').pop()}
                    primaryTypographyProps={{ variant: 'h5' }}
                    secondary={`${yearAndMonth.year} -${month}`}
                  />

                  <Box sx={{ textAlign: 'right' }}>
                    OpNames:{task.counter}
                    <br />
                    {format(new Date(task.Task_createdAt), 'yyyy/MM/dd')}
                    <br />
                    <Typography
                      align="right"
                      variant="body2"
                      color="text.secondary"
                      noWrap
                      sx={{ pt: 0.2 }}
                    >
                      {task.Task_errorsFilePath !== null ? (
                        <Text color="error">
                          <b>{`${task.Task_status}`}</b>
                          <br />
                          <Link
                            href={task.Task_errorsFilePath}
                            rel="external"
                            target="_blank"
                          >
                            Ver Errores
                          </Link>
                        </Text>
                      ) : (
                        <Text color="success">
                          <b>{task.Task_status}</b>
                        </Text>
                      )}
                    </Typography>
                  </Box>
                </ListItemWrapper>
                <Divider />
              </Box>
            );
          })}
        </Card>
      </Container>
    </>
  );
};

export default ContactabilityTasks;
