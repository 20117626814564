import { Box } from '@material-ui/core';
import ContactabilityUpload from '../../../Contactability/Upload';
import ConditionalRender from 'src/components/Guards/Conditional/ConditionalRender';
import ContactabilityTasks from '../Tasks/Contactability/ContactabilityTasks';
import LiveStoreTasks from '../Tasks/LiveStore/LiveStoreTasks';

const ContactSidebar = () => {
  return (
    <div>
      <Box>
        <ConditionalRender roles={['admin']}>
          <Box sx={{ p: { xs: 2, lg: 2 } }}>
            <ContactabilityUpload />
          </Box>
          <Box mb={3}>
            <ContactabilityTasks />
          </Box>
        </ConditionalRender>
        <ConditionalRender roles={['gm']}>
          <Box sx={{ p: { xs: 2, lg: 2 } }}>
            <ContactabilityUpload />
          </Box>
        </ConditionalRender>
        <ConditionalRender roles={['dealer']}>
          <Box mt={2} display="flex" alignItems="center">
            <LiveStoreTasks />
          </Box>
        </ConditionalRender>
      </Box>
    </div>
  );
};

export default ContactSidebar;
