import { Box, Divider, experimentalStyled } from '@material-ui/core';
import React from 'react';
import ConditionalRender from 'src/components/Guards/Conditional/ConditionalRender';
import InxaitLeads from 'src/content/dashboards/Inxait';

import DownloadLeads from '../Reports/SiebelLeads';
import SelectExataUpload from '../Select';
import ManagementTasks from '../Tasks';
import LiveStoreTasks from '../Tasks/LiveStore/LiveStoreTasks';
/* import SelectFtpUpload from '../SelectFtp';
import SelectFtpDownload from '../SelectDownloadFtp'; */

const DividerContrast = experimentalStyled(Divider)(
  ({ theme }) => `
        background: ${theme.colors.alpha.trueWhite[10]};
      `,
);

const ExactaSidebar = () => {
  return (
    <div>
      <Box>
        <ConditionalRender roles={['admin']}>
          <Box sx={{ p: { xs: 2, lg: 2 } }}>
            <SelectExataUpload />
          </Box>
          {/*   <Box sx={{ p: { xs: 2, lg: 2 } }}>
            <SelectFtpUpload />
          </Box> */}
          {/*  <Box sx={{ p: { xs: 2, lg: 2 } }}>
            <SelectFtpDownload />
          </Box> */}
          <Box mb={3} display="flex" alignItems="center">
            <ManagementTasks />
          </Box>
          <DividerContrast />

          {/*  <Box mb={3} display="flex" alignItems="center" mt={5}>
            <DownloadLeads />
          </Box> */}
        </ConditionalRender>
        <ConditionalRender roles={['dealer']}>
          <Box mt={2} display="flex" alignItems="center">
            <LiveStoreTasks />
          </Box>
        </ConditionalRender>
        <ConditionalRender roles={['inxait']}>
          <Box mb={3} mt={2} display="flex" alignItems="center">
            <ManagementTasks />
          </Box>

          <Box mb={3} mt={2} display="flex" alignItems="center">
            <InxaitLeads />
          </Box>
        </ConditionalRender>
        <ConditionalRender roles={['callcenter']}>
          <Box sx={{ p: { xs: 2, lg: 2 } }}>
            <SelectExataUpload />
          </Box>
          <Box mb={3} display="flex" alignItems="center">
            <ManagementTasks />
          </Box>
          <Box mb={3} mt={5} display="flex" alignItems="center">
            <DownloadLeads />
          </Box>
        </ConditionalRender>
      </Box>
    </div>
  );
};

export default ExactaSidebar;
