import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { capitalize } from 'lodash';

import axios from '../../../../../../../utils/axios';

type Campaign = {
  name: string;
  id: number;
};

type CampaignFieldProps = {
  onChange: (Campaign: Campaign) => void;
  value: Campaign;
  error: boolean;
  helperText: React.ReactNode;
};

function CampaignField({
  onChange,
  value,
  error,
  helperText
}: CampaignFieldProps) {
  const [open, setOpen] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const loading = open && campaigns.length === 0;

  useEffect(() => {
    (async () => {
      const { data: response } = await axios.get<Campaign[]>(
        '/campaigns?limit=0&fields=name,id'
      );
      setCampaigns(response);
    })();
  }, [loading]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(e: any, value: any) => onChange(value)}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => capitalize(option.name)}
      options={campaigns}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Campaña"
          variant="outlined"
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}

export default CampaignField;
