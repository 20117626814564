import { IconButton, Popover, Tooltip } from '@material-ui/core';
import { useRef, useState } from 'react';

/* import ThemeToggle from './ThemeToggle'; */
import CountryToggle from './CountryToggle';
import FlagIcon from 'src/components/FlagIcon/FlagIcon';
import ConditionalRender from 'src/components/Guards/Conditional/ConditionalRender';

function HeaderSettings() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <ConditionalRender roles={['admin']}>
        <Tooltip arrow title={'Selector de país'}>
          <IconButton color="primary" ref={ref} onClick={handleOpen}>
            <FlagIcon />
          </IconButton>
        </Tooltip>
      </ConditionalRender>
      <ConditionalRender
        roles={[
          'autotrain',
          'callcenter',
          'callcenter_dealer',
          'dealer',
          'gm',
          'inxait',
          'nodo',
          'supervisor_dealer',
        ]}
      >
        <FlagIcon />
      </ConditionalRender>

      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <ThemeToggle /> */}
        <CountryToggle />
      </Popover>
    </>
  );
}

export default HeaderSettings;
