import { useTasks } from 'src/contexts/TasksContext';
import ContactSidebar from './Sidebars/ContactSidebar';
import ExactaSidebar from './Sidebars/ExactaSidebar';
export enum UploadType {
  PARTICIPANTS = 'participants',
  OR = 'objective_and_results',
}

export const ExactaSidebarPanel = () => {
  const { typePanel } = useTasks();

  return (
    <>
      {typePanel === 'Exacta' ? (
        <ExactaSidebar />
      ) : typePanel === 'Contactabilidad' ? (
        <ContactSidebar />
      ) : null}
    </>
  );
};
