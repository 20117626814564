import { useTasks } from '../../../../contexts/TasksContext';
import Principal from './Sidebars/Principal';
import ContactSidebar from './Sidebars/ContactSidebar';
export enum UploadType {
  PARTICIPANTS = 'participants',
  OR = 'objective_and_results',
}

export const Sidebar = () => {
  const { typePanel } = useTasks();
  return (
    <>
      {typePanel === 'Principal' ? (
        <Principal />
      ) : typePanel === 'Contactabilidad' ? (
        <ContactSidebar />
      ) : null}
    </>
  );
};
