import { useEffect, useReducer } from "react"
import { ContactTask } from "src/common/types/contactTask"
import axios from '../../../../../../utils/axios'
type GetContactabilityTasks = {
    type: 'get-contactability-tasks'
    payload: {
        tasks: ContactTask[]
    }

}
type ActionTypes = GetContactabilityTasks

type State = {
    tasks: ContactTask[]
}
const initalState: State = {
    tasks: []
}
const Reducer = (state: State, action: ActionTypes) => {
    switch (action.type) {
        case 'get-contactability-tasks':
            const { tasks } = action.payload
            return {
                ...state,
                tasks
            }


        default:
            return state
    }

}
export interface ContactTasksReducer extends State {
    getContactabilityTasks: () => void
}
export const useContactTasksReducer = (): ContactTasksReducer => {
    const [state, dispatch] = useReducer(Reducer, initalState)
    const getContactabilityTasks = async () => {
        const { data: tasks } = await axios.get('/not-contacted-leads/last/3')
        dispatch({
            type: 'get-contactability-tasks',
            payload: {
                tasks
            }
        })
    }
    useEffect(() => {
        getContactabilityTasks()
    }, [])
    return {
        ...state,
        getContactabilityTasks
    }
}
