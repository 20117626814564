import { useContext } from 'react';

import { Box, Hidden, IconButton, Tooltip } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import { colors } from '../../../theme/schemes/GmTheme';

import HeaderMenu from './Menu';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import Logo from 'src/components/Logo';
import HeaderSettings from './Buttons/Settings';

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.xl}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`,
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Logo />
        <HeaderSettings />
        <Hidden xlUp>
          <Tooltip arrow title="Menú">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon
                  sx={{
                    color: colors.gm.white,
                  }}
                />
              ) : (
                <CloseTwoToneIcon
                  sx={{
                    color: colors.gm.white,
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Hidden>

        <Hidden mdDown>
          <HeaderMenu />
        </Hidden>
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <HeaderUserbox />
        {/* <Hidden lgUp> */}
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
