import PropTypes from 'prop-types';

import {
    Box,
    Card,
    Divider,
    ListItemText,
    Typography,
    experimentalStyled,
    ListItem,
    Link,
} from '@material-ui/core';

import { format } from 'date-fns';
import Text from 'src/components/Text';
import { Task } from 'src/common/types/task';

const ListItemWrapper = experimentalStyled(ListItem)(
    ({ theme }) => `
          border-radius: 0;
          padding: ${theme.spacing(3)};
  `,
);

const LastLiveStoreTasks = ({ tasks }) => {
    return (
        <>
            <Card>
                {tasks.map((task: Task) => {
                    return (
                        <Box key={task.id}>
                            <ListItemWrapper button>
                                <ListItemText
                                    primary={(task?.filePath || '').split('/').pop()}
                                    primaryTypographyProps={{ variant: 'h5' }}
                                    secondary={`${format(
                                        new Date(task.updatedAt),
                                        'yyyy/MM/dd HH:mm',
                                    )}`}
                                    secondaryTypographyProps={{ variant: 'subtitle1' }}
                                />

                                <Box sx={{ textAlign: 'right' }}>
                                    ID:{task.id}
                                    <br />
                                    <b>{task.type}</b>

                                    <br />
                                    <Typography
                                        align="right"
                                        variant="body2"
                                        color="text.secondary"
                                        noWrap
                                        sx={{ pt: 0.5 }}
                                    >
                                        {task.errorsFilePath !== null ? (
                                            <Text color="error">
                                                <b>{`${task.status}`}</b>
                                                <br />
                                                <Link
                                                    href={task.errorsFilePath}
                                                    rel="external"
                                                    target="_blank"
                                                >
                                                    Ver Errores
                                                </Link>
                                            </Text>
                                        ) : (
                                            <Text color="success">
                                                <b>{task.status}</b>
                                            </Text>
                                        )}
                                    </Typography>
                                </Box>
                            </ListItemWrapper>
                            <Divider />
                        </Box>
                    );
                })}
            </Card>
        </>
    );
};

LastLiveStoreTasks.propTypes = {
    tasks: PropTypes.array.isRequired,
};

LastLiveStoreTasks.defaultProps = {
    tasks: [],
};

export default LastLiveStoreTasks;
