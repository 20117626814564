import { Box } from '@material-ui/core';

/* import HeaderNotifications from './Notifications'; */
import HeaderTasks from './Tasks';
/* import SearchLead from './Search'; */
import ConditionalRender from 'src/components/Guards/Conditional/ConditionalRender';
import HeaderDownloadReportBox from '../DownloadReportBox';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import ExataHeaderDownloadReportBox from '../DownloadReportBox/ExataDownloadBox';

function HeaderButtons() {
  const { customHeader } = useContext(SidebarContext);
  return (
    <Box
      // sx={{ mr: 1 }}
      style={{
        display: 'flex',
      }}
    >
      <ConditionalRender roles={['admin']}>
        {customHeader === 'normal' ? (
          <HeaderDownloadReportBox />
        ) : (
          <ExataHeaderDownloadReportBox />
        )}

        {/*  <SearchLead /> */}
        {/* <HeaderNotifications /> */}
        <HeaderTasks />
      </ConditionalRender>
      <ConditionalRender roles={['dealer', 'gm', 'callcenter', 'inxait']}>
        {/*     <SearchLead /> */}
        <HeaderTasks />
      </ConditionalRender>
      <ConditionalRender roles={['nodo', 'autotrain']}>
        {/*       <SearchLead /> */}
      </ConditionalRender>
    </Box>
  );
}

export default HeaderButtons;
