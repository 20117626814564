export const generalConfig: {
  backendURL: string;
  instanceSlug: 'cl' | 'pe' | 'co' | 'ec';
  instanceName: 'Chile' | 'Peru' | 'Colombia' | 'Ecuador';
} = {
  backendURL: process.env.REACT_APP_API_URL,
  instanceSlug: process.env.REACT_APP_GM_INSTANCE_SLUG as any,
  instanceName: process.env.REACT_APP_GM_INSTANCE_NAME as any,
};
export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_FIREBASE_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_FIREBASE_APP_ID,
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
