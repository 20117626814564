import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  lighten,
  List,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import { colors } from '../../../../theme/schemes/GmTheme';

import useAuth from 'src/hooks/useAuth';
import { getUsernameOfAnEmail } from '../../../../utils/common-helpers';
import { Person } from '@material-ui/icons';
import { useEditProfileReducer } from './reducer';
import Text from 'src/components/Text';
import { useSnackbar } from 'notistack';

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
);

function HeaderUserbox() {
  const history = useHistory();
  const { user, logout } = useAuth();
  const { openModal, closeModal, handleChangePassword, isEditPasswordModalOpen, editPassword, formValues } = useEditProfileReducer()
  const { enqueueSnackbar } = useSnackbar()

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmitPassword = (e) => {
    e.preventDefault();
    const { password1, password2 } = formValues
    if (
      password1 === password2
    ) {
      editPassword(user.id);
    }
    else {
      enqueueSnackbar('las contraseñas no coinciden', { variant: 'error' })
    }
  };
  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
        <Avatar
          variant="rounded"
          alt={user.fullName ? user.fullName : getUsernameOfAnEmail(user.email)}
          src=""
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel
              variant="body1"
              sx={{
                color: colors.gm.white,
              }}
            >
              {user.fullName ? user.fullName : getUsernameOfAnEmail(user.email)}
            </UserBoxLabel>
            <UserBoxDescription
              variant="body2"
              sx={{
                // color: colors.gm.white,
                color: '#F00',
              }}
            >
              {renderRole(user.roles[0])}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
          <Avatar variant="rounded" alt={user.fullName} src="" />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.fullName}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {/* {capitalize(`${user.roles[0].name}`)} */}
              {renderRole(user.roles[0])}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>

        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          {/* <ListItem button to="/preview/management/user/1" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem
            button
            to="/preview/applications/mailbox/inbox"
            component={NavLink}
          >
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <ListItem
            button
            to="/preview/applications/projects-board"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Projects')} />
          </ListItem> */}
        </List>
        <Dialog open={isEditPasswordModalOpen} onClose={closeModal}>
          <form onSubmit={handleSubmitPassword}>
            <DialogTitle>Perfil</DialogTitle>
            <DialogContent>
              <CardContent sx={{ p: 0 }}>
                <Typography variant="subtitle2">
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={3}
                      sm={4}
                      md={3}
                      textAlign={{ sm: 'right' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                      }}
                    >
                      <Box pr={3}>Usuario:</Box>
                    </Grid>
                    <Grid item xs={3} sm={8} md={9}>
                      <Text color="black">
                        <b>{user.email}</b>
                      </Text>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={4}
                      md={3}
                      textAlign={{ sm: 'right' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                      }}
                    >
                      <Box pr={3}>Nueva Contraseña:</Box>
                    </Grid>
                    <Grid item xs={3} sm={8} md={9}>
                      <TextField
                        sx={{
                          my: 1,
                        }}
                        name="password1"
                        onChange={(e) => handleChangePassword(e.target.name, e.target.value)}
                        type="password"
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={4}
                      md={3}
                      textAlign={{ sm: 'right' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                      }}
                    >
                      <Box pr={3}>Repita la Contraseña:</Box>
                    </Grid>
                    <Grid item xs={3} sm={8} md={9}>
                      <TextField
                        sx={{
                          my: 1,
                        }}
                        name="password2"
                        onChange={(e) => handleChangePassword(e.target.name, e.target.value)}
                        type="password"
                        required
                      />
                    </Grid>
                  </Grid>

                </Typography>

              </CardContent>
            </DialogContent>
            <DialogActions sx={{ py: 3, mx: 4 }}>
              <Button color="secondary" onClick={closeModal}>
                Cancelar
              </Button>
              <Button type="submit" variant="contained" >
                Guardar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Divider />

        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={openModal}>
            <Person />
            Actualizar Perfil
          </Button>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Salir
          </Button>
        </Box>
      </Popover>
    </>
  );
}

const renderRole = (role: { id: number; name: string }) => {
  switch (role.name) {
    case 'admin':
      return 'Administrador';
    default:
      return 'Equipo GM';
  }
};

export default HeaderUserbox;
