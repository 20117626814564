import React from 'react'
import { CL, EC, CO, PE } from 'country-flag-icons/react/3x2';
import { ListItem, ListItemText } from '@material-ui/core';
import { instances } from 'src/common/types/instances'
import useAuth from 'src/hooks/useAuth';

const CountriesSelector = () => {
    const { accessToken } = useAuth();
    return (
        <>

            {//eslint-disable-next-line
                instances.map(instance => {
                    switch (instance.name) {
                        case 'chile':
                            return <>
                                <ListItem button>
                                    <a
                                        href={`${instance.url}/?token=${accessToken}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            all: 'unset', display: 'flex', flexDirection: 'row'
                                        }}
                                    >
                                        <CL title="Chile" style={{ width: 20, height: 20, marginRight: 5 }} />
                                        <ListItemText primary="Chile" />
                                    </a>
                                </ListItem>
                            </>
                        case 'ecuador':
                            return <>
                                <ListItem button>
                                    <a
                                        href={`${instance.url}/?token=${accessToken}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            all: 'unset', display: 'flex', flexDirection: 'row'
                                        }}
                                    >
                                        <EC title="Ecuador" style={{ width: 20, height: 20, marginRight: 5 }} />
                                        <ListItemText primary="Ecuador" />
                                    </a>
                                </ListItem>
                            </>
                        case 'colombia':
                            return <>
                                <ListItem button>
                                    <a
                                        href={`${instance.url}/?token=${accessToken}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            all: 'unset', display: 'flex', flexDirection: 'row'
                                        }}
                                    >
                                        <CO
                                            title="Colombia"
                                            style={{ width: 20, height: 20, marginRight: 5 }}
                                        />
                                        <ListItemText primary="Colombia" />
                                    </a>
                                </ListItem>

                            </>
                        case 'peru':
                            return <>
                                <ListItem button>
                                    <a
                                        href={`${instance.url}/?token=${accessToken}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            all: 'unset', display: 'flex', flexDirection: 'row'
                                        }}
                                    >
                                        <PE title="Peru" style={{ width: 20, height: 20, marginRight: 5 }} />
                                        <ListItemText primary="Peru" />
                                    </a>
                                </ListItem>

                            </>
                        default:
                            break;
                    }
                }
                )}
        </>
    )
}

export default CountriesSelector