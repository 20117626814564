import { useEffect, FC } from 'react';
import {
  Container,
  Box,
  IconButton,
  Typography,
  Link,
} from '@material-ui/core';
import { useTaskReducer } from '../../../../../layouts/SidebarLayout/Header/Buttons/Tasks/reducer';
import { experimentalStyled } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Task as TaskType } from 'src/common/types/task';
import { format } from 'date-fns';
import Refresh from '@material-ui/icons/Refresh';

import Label from 'src/components/Label';
import ContentWrapper from 'src/components/ContentWrapper';

const CardBox = experimentalStyled(Box)(
  ({ theme }) => `
      background: ${theme.colors.alpha.trueWhite[10]};
        border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
  `,
);

const TypographyPrimary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
  `,
);

const TypographySecondary = experimentalStyled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[70]};
  `,
);

function ManagementTasks() {
  const { getAllTasks, tasks } = useTaskReducer();
  useEffect(() => {
    getAllTasks();
    // eslint-disable-next-line
  }, []);
  return (
    <ContentWrapper title="Tasks - Management">
      <Container maxWidth="lg">
        <CardBox sx={{ height: '100%' }}>
          <TypographyPrimary variant="h4" gutterBottom>
            Tareas
            <IconButton
              aria-label="refresh"
              color="primary"
              onClick={() => getAllTasks()}
              title="Refrescar Tareas"
            >
              <Refresh />
            </IconButton>
          </TypographyPrimary>
          <TypographySecondary variant="body1" pb={2}>
            Revise el estado de las últimas 5 tareas
          </TypographySecondary>
          <Link
            href="/dashboards/pending-tasks"
            underline="none"
            style={{ margin: 10 }}
          >
            {'Ver mas...'}
          </Link>

          <Box p={0}>
            <Timeline>
              {tasks && tasks.map((filteredTask) => <Task {...filteredTask} />)}
            </Timeline>
          </Box>
        </CardBox>

        {/* <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  <>
                    <TextField
                      size="small"
                      fullWidth={mobile}
                      onChange={handleQueryChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchTwoToneIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={'Busque una Tarea...'}
                    />
                  </>
                }
                title={'Tareas'}
              />

              <Link
                href="/dashboards/pending-tasks"
                underline="none"
                style={{ margin: 10 }}
              >
                {'Ver mas...'}
              </Link>
              <IconButton
                aria-label="refresh"
                color="primary"
                onClick={() => getAllTasks()}
                title="Refrescar Tareas"
              >
                <Refresh />
              </IconButton>
              <Divider />
              <List disablePadding>
                <Divider component="li" />
                <Results
                  tasks={filteredTasks}
                  start={start}
                  search={search}
                  lastTasks={lastFilteredTasks}
                />
              </List>
              <CardActions
                disableSpacing
                sx={{ p: 2, display: 'flex', justifyContent: 'center' }}
              ></CardActions>
            </Card>
          </Grid>
        </Grid> */}
      </Container>
    </ContentWrapper>
  );
}

export const Task: FC<TaskType> = ({
  id,
  status,
  errorsFilePath,
  type,
  createdAt,
}) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot variant="outlined" color="secondary" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent display="flex" alignItems="center">
        <Box sx={{ mr: 3 }}>
          <Typography
            variant="h5"
            noWrap
            style={{
              color: 'white',
            }}
          >
            #{id}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            <StatusTask status={status} errorsFilePath={errorsFilePath} />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography
              variant="h5"
              noWrap
              style={{
                color: 'white',
              }}
            >
              {type}
            </Typography>
            <TypographySecondary variant="body2" color="text.secondary" noWrap>
              {format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss')}
            </TypographySecondary>
          </Box>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

const StatusTask: FC<{
  status: TaskType['status'];
  errorsFilePath: TaskType['errorsFilePath'];
}> = ({ status, errorsFilePath }) => {
  if (status === 'PENDIENTE') return <Label color="info">Pendiente</Label>;
  if (status === 'EJECUTANDO') return <Label color="warning">Procesando</Label>;
  if (status === 'COMPLETADA' && errorsFilePath)
    return <Label color="error">Error</Label>;
  if (status === 'COMPLETADA') return <Label color="success">Completada</Label>;
  return <></>;
};

export default ManagementTasks;
