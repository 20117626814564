import { useTranslation } from 'react-i18next';
import { Box, List, Divider, Typography } from '@material-ui/core';

import { experimentalStyled } from '@material-ui/core/styles';
import CountriesSelector from 'src/components/CountriesSelector';




const SectionHeading = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`,
);

function CountryToggle() {
  const { t }: { t: any } = useTranslation();


  return (
    <Box>
      <SectionHeading variant="body2" color="text.primary">
        {t('Selector de país')}
      </SectionHeading>
      <List sx={{ p: 2 }} component="nav">
        <CountriesSelector />
      </List>
      <Divider />
    </Box>
  );
}

export default CountryToggle;
