import { FC, ReactNode } from 'react';
import useAuth from 'src/hooks/useAuth';

import { Role } from '../../../common/types/user';

interface AuthenticatedProps {
  children?: ReactNode;
  roles?: Role[] | null;
}

export const RolesRender: FC<AuthenticatedProps> = ({ children, roles }) => {
  const { user } = useAuth();

  if (roles) {
    let hasAtLeastOneRole = false;

    // eslint-disable-next-line
    user.roles.map((userRole) => {
      const coincidences = roles.filter((role) => role === userRole.name);
      if (coincidences.length > 0) {
        hasAtLeastOneRole = true;
        // eslint-disable-next-line
        // return <><;
      }
    });

    if (!hasAtLeastOneRole) return <></>;
  }
  return <>{children}</>;
};
