export const removeProgramId = (field: string): string => {
  const purgedLoginCredential = field.substring(field.indexOf('_') + 1);
  return purgedLoginCredential;
};

export const getUsernameOfAnEmail = (email: string): string => {
  const purgedEmail = email.split('@')[0];
  return purgedEmail;
};

export const getMonthName = (monthNumber: string) => {
  const parsedMonthNumber = parseInt(monthNumber);
  switch (parsedMonthNumber) {
    case 1:
      return 'Enero';
    case 2:
      return 'Febrero';
    case 3:
      return 'Marzo';
    case 4:
      return 'Abril';
    case 5:
      return 'Mayo';
    case 6:
      return 'Junio';
    case 7:
      return 'Julio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Septiembre';
    case 10:
      return 'Octubre';
    case 11:
      return 'Noviembre';
    case 12:
      return 'Diciembre';
    default:
      return '';
  }
};
