import { Link } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';

const LogoWrapper = experimentalStyled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`,
);

function Logo() {
  return (
    <LogoWrapper to="/dashboards/inicio">
      <img
        alt="logo_101"
        src="/static/images/logo/LOGO-blanco.png"
        style={{
          width: 110,
          backgroundColor: '#409ed8'
        }}
      // style={{ height: 90, width: 90 }}
      />
    </LogoWrapper>
  );
}

export default Logo;
