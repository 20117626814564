import axios from '../../../../utils/axios';
import { useSnackbar } from 'notistack';
import { useReducer } from 'react';

type ChangePassword = {
  type: 'change-password';
  payload: {
    field: any;
    value: any;
  };
};
type OpenEditModal = {
  type: 'open-edit-modal';
};
type CloseEditModal = {
  type: 'close-edit-modal';
};
type ResetValues = {
  type: 'reset-values';
};
type ActionTypes =
  | ChangePassword
  | OpenEditModal
  | CloseEditModal
  | ResetValues;
type FormValuesState = {
  password1: string | null;
  password2: string | null;
};
const initialFormValues: FormValuesState = {
  password1: null,
  password2: null,
};
type State = {
  isEditPasswordModalOpen: boolean;
  formValues: FormValuesState;
  userId: number | null;
};
const initalState: State = {
  isEditPasswordModalOpen: false,
  formValues: initialFormValues,
  userId: null,
};
const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case 'change-password':
      const { field, value } = action.payload;
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [field]: value,
        },
      };

    case 'open-edit-modal':
      return {
        ...state,
        isEditPasswordModalOpen: true,
      };
    case 'close-edit-modal':
      return {
        ...state,
        isEditPasswordModalOpen: false,
      };
    case 'reset-values':
      return {
        ...state,
        password: null,
        userId: null,
      };
    default:
      return state;
  }
};

export const useEditProfileReducer = () => {
  const [state, dispatch] = useReducer(reducer, initalState);
  const { enqueueSnackbar } = useSnackbar();
  const openModal = () => {
    dispatch({ type: 'open-edit-modal' });
  };
  const closeModal = () => {
    dispatch({ type: 'close-edit-modal' });
  };
  const handleChangePassword = (field: any, value: any) => {
    dispatch({ type: 'change-password', payload: { field, value } });
  };
  const editPassword = async (userId: number) => {
    const { password1 } = state.formValues;
    if (password1 !== null && userId !== null) {
      try {
        await axios.patch(`/users/${userId}`, {
          id: userId,
          password: password1,
        });

        enqueueSnackbar('se cambio la contraseña con éxito.', {
          variant: 'success',
        });

        dispatch({
          type: 'reset-values',
        });
        dispatch({
          type: 'close-edit-modal',
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('ha ocurrido un error al cambiar la contraseña.', {
          variant: 'error',
        });
        dispatch({
          type: 'reset-values',
        });
        dispatch({
          type: 'close-edit-modal',
        });
      }
    } else {
      enqueueSnackbar(
        'Debe ingresar una nueva contraseña no puede dejar el espacio vacio.',
        { variant: 'error' },
      );
    }
  };
  return {
    ...state,
    openModal,
    closeModal,
    editPassword,
    handleChangePassword,
  };
};
