import { useReducer } from 'react';
import { Task } from '../../../../../common/types/task';
import axios from 'src/utils/axios';
type LoadingDirectUpload = {
  type: 'loadingLastUpload';
  payload: {
    task: Task;
  };
};
type getAllTasks = {
  type: 'getAllTasks';
  payload: {
    tasks: Task[];
  };
};
type ActionTypes = LoadingDirectUpload | getAllTasks;

export type State = {
  status: 'loading' | 'complete';
  task: Task;
  tasks: Task[];
};
const initialState: State = {
  status: 'loading',
  task: null,
  tasks: [],
};
const tasksReducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case 'loadingLastUpload':
      const { task } = action.payload;
      return {
        ...state,
        task,
        status: 'complete',
      };
    case 'getAllTasks':
      const { tasks } = action.payload;
      return {
        ...state,
        tasks,
        status: 'complete',
      };
    default:
      return state;
  }
};
export const useTaskReducer = () => {
  const [state, dispatch] = useReducer(tasksReducer, initialState);
  const getLastDirectUpload = async () => {
    const query = 'limit=5&page=1&sort%5B0%5D=createdAt%2CDESC';
    const { data: tasks } = await axios.get(`/tasks?${query}`);
    const lastDirectUpload: Task = tasks.data[0];
    dispatch({
      type: 'loadingLastUpload',
      payload: {
        task: lastDirectUpload,
      },
    });
  };
  const getAllTasks = async () => {
    const query = 'limit=5&page=1&sort%5B0%5D=createdAt%2CDESC';
    const { data: tasks } = await axios.get(`/tasks?${query}`);

    dispatch({
      type: 'getAllTasks',
      payload: {
        tasks: tasks.data,
      },
    });
  };
  return {
    ...state,
    getLastDirectUpload,
    getAllTasks,
  };
};
