import React from 'react';
import { Field, Form, Formik, FieldProps } from 'formik';
import { TextField, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { object, string } from 'yup';

const instance = process.env.REACT_APP_GM_INSTANCE_SLUG;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(2)
    },
    formField: {
      minWidth: '120px',
      // width: '100%'
      '&.margin': {
        marginLeft: theme.spacing(2)
      }
    },
    buttons: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  })
);

const validationSchemaGlobal = object().shape({
  month: string().required('Requerido'),
  year: string().required('Requerido')
});

const validationSchemaEcCo = object().shape({
  month: string()
    .required('Requerido')
    // Just in case instance is Ec or Co, we allow to upload since April 2020
    .test(
      'is-valid-month',
      'Abril del 2020 en adelante',
      function validateMonth(value) {
        if (this.parent.year === '2020') {
          if (Number(value) <= 3) {
            return false;
          }
        }
        return true;
      }
    ),
  year: string().required('Requerido')
});

const currentYear = new Date().getFullYear();
const months = [
  { name: 'Enero', value: '01' },
  { name: 'Febrero', value: '02' },
  { name: 'Marzo', value: '03' },
  { name: 'Abril', value: '04' },
  { name: 'Mayo', value: '05' },
  { name: 'Junio', value: '06' },
  { name: 'Julio', value: '07' },
  { name: 'Agosto', value: '08' },
  { name: 'Septiembre', value: '09' },
  { name: 'Octubre', value: '10' },
  { name: 'Noviembre', value: '11' },
  { name: 'Diciembre', value: '12' }
];
const availableMonths = months;

const years = (() => {
  let year = 2021;
  const res = [];
  while (year <= currentYear) {
    res.push(`${year}`);
    year += 1;
  }
  return res;
})();

type YearMonthFormProps = {
  onSubmit: (values: any) => void;
  renderButtons: () => React.ReactNode;
};

function YearMonthForm({ onSubmit, renderButtons }: YearMonthFormProps) {
  const styles = useStyles();
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={
        instance === 'ec' || instance === 'co'
          ? validationSchemaEcCo
          : validationSchemaGlobal
      }
      onSubmit={onSubmit}
      initialValues={{ month: '', year: '' }}
    >
      {({ errors }) => (
        <Form className={styles.form}>
          <Field name="month">
            {({ field }: FieldProps) => (
              <TextField
                select
                variant="outlined"
                label="Mes"
                className={styles.formField}
                error={errors.month !== undefined}
                helperText={errors.month}
                {...field}
              >
                {availableMonths.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
          <Field name="year">
            {({ field }: FieldProps) => (
              <TextField
                select
                variant="outlined"
                label="Año"
                className={`${styles.formField} margin`}
                error={errors.year !== undefined}
                helperText={errors.year}
                {...field}
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
          <div className={styles.buttons}>{renderButtons()}</div>
        </Form>
      )}
    </Formik>
  );
}

export default YearMonthForm;
