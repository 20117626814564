import React, {
  FC,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
} from 'react';
import jwtDecode from 'jwt-decode';
// import { User, SystemUser } from 'src/models/user';
import SuspenseLoader from 'src/components/SuspenseLoader';
import axios from 'src/utils/axios';

import { User, LoggedUser } from '../common/types/user';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  loginWithToken: (accesToken: string) => void;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};
type GetAccessToken = {
  type: 'get-access-token';
  payload: {
    accessToken: string;
  };
};
type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | GetAccessToken;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  accessToken: null,
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'get-access-token': {
      const { accessToken } = action.payload;
      return {
        ...state,
        accessToken,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  loginWithToken: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const { enqueueSnackbar } = useSnackbar();
  let query = useQuery();
  let history = useHistory();
  const login = async (
    email: string,
    password: string,
    isGoogleLogin?: boolean,
    gAccessToken?: string,
    gUser?: User,
  ) => {
    // Is a Google Authenticated used
    if (gAccessToken && gUser) {
      setSession(gAccessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: gUser,
        },
      });
      return;
    }
    try {
      const loginResponse = await axios.post<LoggedUser>('/auth/login', {
        isGoogleLogin,
        username: email,
        password,
      });
      const { accessToken } = loginResponse.data;
      dispatch({
        type: 'get-access-token',
        payload: {
          accessToken,
        },
      });
      setSession(accessToken);
      const userResponse = await axios.get<User>(`/users/me`);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: userResponse.data,
        },
      });
      enqueueSnackbar('Bienvenido.', {
        variant: 'success',
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Credenciales Incorrectas o usuario inactivo.', {
        variant: 'error',
      });
    }
  };
  const loginWithToken = () => {
    setSession(query?.get('token')?.toString());
    history.push('/');
  };
  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        query?.get('token')?.toString !== undefined && loginWithToken();
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          dispatch({
            type: 'get-access-token',
            payload: {
              accessToken: accessToken,
            },
          });
          const response = await axios.get<User>('/users/me');
          const user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
    //eslint-disable-next-line
  }, []);

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        loginWithToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
