import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import routes, { renderRoutes } from './router';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ScrollToTop from './utils/ScrollToTop';
import es from 'date-fns/locale/es';

import { AuthProvider } from './contexts/JWTAuthContext';
import { AuthProvider as AuthProviderFirebase } from './contexts/FirebaseAuthContext';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@material-ui/core';

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Router history={history}>
              <ScrollToTop />
              <CssBaseline />
              <AuthProvider>
                <AuthProviderFirebase>
                  {renderRoutes(routes)}
                </AuthProviderFirebase>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
