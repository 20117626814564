import { createContext, useContext, useState } from 'react';

export const TasksContext = createContext({
  typePanel: 'Principal',
  createPanel: (name: any) => {},
});

export const useTasks = () => useContext(TasksContext);
export const TaskProvider = ({ children }) => {
  const [typePanel, setTypePanel] = useState('Exacta');
  const createPanel = (name: any) => {
    setTypePanel(name);
  };
  return (
    <TasksContext.Provider value={{ typePanel, createPanel }}>
      {children}
    </TasksContext.Provider>
  );
};
